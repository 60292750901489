body {
  // font-family: 'CenturyGothic' !important;
  font-family: 'Century Gothic' !important;
}

.App {
  text-align: center;
  font-family: 'Century Gothic' !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Century Gothic' !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-tooltip-placement-top > .ant-tooltip-arrow {
  background-color: #1a1a1a !important;
  position: absolute;
  bottom: -6px !important;
  left: 49% !important;
  transform: rotate(45deg);
}
.ant-tooltip-placement-bottom > .ant-tooltip-arrow {
  background-color: #1a1a1a !important;
  position: absolute;
  top: -6px !important;
  left: 49% !important;
  transform: rotate(45deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  background-color: #1a1a1a !important;
  position: absolute;
  right: -5px !important;
  top: 30% !important;
  transform: rotate(45deg);
  z-index: 0;
}
.ant-tooltip-arrow:before {
  background: #1a1a1a !important;
}
.ant-tooltip .ant-tooltip-inner {
  background-color: #1a1a1a;
}

.hoverLink {
  &:hover {
    color: rgba(230, 12, 26, 0.75);
  }
}

.swiper-pagination-bullet-active {
  background-color: #e60c19 !important;
  width: 20px !important;
  height: 10px !important;
  border-radius: 8px !important;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 30px !important;
}

// ant classes
.ant-menu-submenu {
  z-index: 999;
}

[class^="ant"] {
  /* Your CSS styles here */
  font-family: 'Century Gothic' !important;
}

input{
  font-family: 'Century Gothic' !important;
}

.publicWrapper {
  padding: 30px 60px !important;
  font-family: 'Century Gothic' !important;
  @media screen and (min-width: 1280px) {
    padding: 30px 60px !important;
  }
  @media screen and (min-width: 1440px) {
    padding: 30px 60px !important;
  }
  @media screen and (min-width: 1920px) {
    padding: 60px 120px !important;
  }
  @media screen and (max-width: 767px) {
    padding: 60px 120px !important;
  }
  @media screen and (max-width: 430px) {
    padding: 20px 30px !important;
  }
}
.privateWrapper {
  padding-left: 60px;
  padding-right: 60px;
  font-family: 'Century Gothic' !important;
  @media screen and (min-width: 1280px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (min-width: 1440px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (min-width: 1920px) {
    padding-left: 120px;
    padding-right: 120px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (max-width: 414px) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (min-width: 1580px) and (max-width: 1680px) {
  .mt-1600-0 {
    margin-top: 0px !important;
  }
  .mt-1600-1 {
    margin-top: 8px !important;
  }
  .mt-1600-2 {
    margin-top: 16px !important;
  }
  .mt-1600-3 {
    margin-top: 24px !important;
  }
  .mb-1600-0 {
    margin-bottom: 0px !important;
  }
  .mb-1600-1 {
    margin-bottom: 8px !important;
  }
  .mb-1600-2 {
    margin-bottom: 16px !important;
  }
  .mb-1600-3 {
    margin-bottom: 24px !important;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .mt-1300-0 {
    margin-top: 0px !important;
  }
  .mt-1300-1 {
    margin-top: 8px !important;
  }
  .mt-1300-2 {
    margin-top: 16px !important;
  }
  .mt-1300-3 {
    margin-top: 24px !important;
  }
  .mb-1300-0 {
    margin-bottom: 0px !important;
  }
  .mb-1300-1 {
    margin-bottom: 8px !important;
  }
  .mb-1300-2 {
    margin-bottom: 16px !important;
  }
  .mb-1300-3 {
    margin-bottom: 24px !important;
  }
  .publicWrapper {
    padding: 16px 56px !important;
  }
}
.headerTopSpacing {
  padding-top: 80px;
  @media screen and (min-width: 1280px) {
    padding-top: 76px;
  }
  @media screen and (min-width: 1440px) {
    padding-top: 80px;
  }
  @media screen and (min-width: 1920px) {
    padding-top: 80px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 80px;
  }
  @media screen and (max-width: 414px) {
    padding-top: 74px;
  }
}