.messageWrapper::-webkit-scrollbar {
  display: none;
}
.messageWrapper {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.messageFloatButton{

  .ant-badge {
    .ant-scroll-number{
      top: 0%;
      right: 0%;
      font-size: 10px;
    }
  }
.ant-float-btn-body{
    .ant-float-btn-content{
      height: 100%;
      width: 100%;
.ant-float-btn-icon{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.profileActiveBadge{
  .ant-badge-dot{
    width: 8px;
    height: 8px;
    left: 0%;
    top: 14%;
  }
}
.messageOverlay{
  .ant-float-btn-primary{
    .ant-badge{
      .ant-badge-count{
        background-color: #E60C19;
      }
    }
  }
}
.pickerBox{
  position: relative;
.emojiPickerSt{
    position: absolute;
    bottom: 124%;
    left: 0%;
    width: 300px !important;
    height: 300px !important;
.epr_q53mwh{
      // display: none;
    }
:last-child{
    }
  }
}
.iconMyBe{
  &::before{
    margin-left: 0px;
  }
}
.chatTextArea{
  outline: none;
  // .ant-input-outlined:hover{
  border-color: transparent !important;
  // }
  padding-left: 2px;
&:focus{
    box-shadow: none;
  }
  &::-webkit-scrollbar {
    width: 2px; /* width of the entire scrollbar */
    margin: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f9f9f9; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #E60C19; /* color of the scroll thumb */

    border-radius: 20px; /* roundness of the scroll thumb */
    border: 10px solid #E60C19; /* creates padding around scroll thumb */
  }
}
.popupChatBox{
  .ant-popover-content{
    .ant-popover-inner{
      border-radius: 12px;
      padding: 0px !important;
    }
  }
  position: fixed;
  z-index: 999;
}

.custom-rounded-full .ant-select-selector {
  border-radius: 9999px;
}

.custom-rounded-full-dropdown .ant-select-item-option:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
