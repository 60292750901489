@font-face {
  font-family: 'Century Gothic'; /* Specify a name for your font */
  src: url('./assests/Fonts/CenturyGothic.ttf') format('woff2'); /* Adjust the path to your font file */
  font-weight: normal;
  font-style: normal;
}

body {

  font-family: 'Century Gothic', sans-serif;
  &::-webkit-scrollbar {
    width: 6px; /* width of the entire scrollbar */
    margin: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f9f9f9;
    /* color of the tracking area */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #E60C19; /* color of the scroll thumb */

    border-radius: 20px; /* roundness of the scroll thumb */
    border: 10px solid #E60C19; /* creates padding around scroll thumb */
  }

  ::-moz-selection { /* Code for Firefox */
    color: white;
    background: #E60C19;
  }

  ::selection {
    color: white;
    background: #E60C19;
  }
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~antd/dist/reset.css';
