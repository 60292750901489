  .desktopMenu {
    font-family: 'Century Gothic' !important;
    li {
      display: flex !important;
      align-items: center !important;
      &:hover {
        border-bottom-width: 4px !important;
        border-radius: 4px 4px 0 0 !important;
      }

      // span {
      //   display: flex !important;
      //   align-items: center !important;
      //   height: 100%;
      // }

      div {
        display: flex !important;
        align-items: center !important;
        height: 100%;
      }
    }
  }

  .ant-menu-item-selected::after {
    border-bottom-width: 4px !important;
    border-radius: 4px 4px 0 0 !important;
  }
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected::after {
    border-bottom-width: 4px !important;
    border-radius: 4px 4px 0 0 !important;
  }
.mobileMenu{

}
.mobileDrawerMenu{
    .ant-drawer-header{
    display: flex;
    justify-content: end;
.ant-drawer-header-title{
      display: flex;
      justify-content: end;
button{
          margin: 0;
        }
      }
    }
.ant-drawer-body{
      padding: 0px;
.ant-menu{
        border-inline-end:none;
      }
    }
  }
.subMenuPadding{
    font-family: 'Century Gothic' !important;
    padding-left: 6px !important;
    padding-right: 12px !important;
    @media screen and (max-width: 820px) {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
  }
.ant-menu-horizontal{
    border-bottom: none !important;
  }
.myHeaderShadow{
    box-shadow: rgba(8, 9, 10, 0.1) 0px 10px 10px -10px;
    // border-bottom: 1px solid red;
    height: 80px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    @media screen and (min-width: 1280px) {
      height: 76px;

    }
    @media screen and (min-width: 1440px) {
      height: 80px;

    }
    @media screen and (min-width: 1920px) {
      height: 80px;

    }
    @media screen and (max-width: 767px) {
      height: 80px;

    }
    @media screen and (max-width: 414px) {
      height: 74px;

    }
  }